export default {
  name: 'MessagePreview',
  props: ['campaign', 'fields', 'preview'],
  data: function () {
    return {
      contactPreview: 0,
      message: '',
    }
  },
  computed: {
  },
  watch: {
    campaign: {
      handler () {
        this.messagePreview()
      },
      deep: true,
    },
  },
  methods: {
    messagePreview () {
      if (typeof (this.preview.contactsEmail) === 'undefined') {
        return false
      }

      if (this.preview.contactsEmail.length > 0) {
        if (this.contactPreview === 0) {
          this.contactPreview = this.preview.contactsEmail[0]
          this.message = this.changeSmsPreview(this.contactPreview)
        } else {
          this.message = this.changeSmsPreview(this.contactPreview)
        }
      }
    },
    changeSmsPreview (index) {
      const contactFields = this.preview.contactsFieldsValue[index]

      let message = this.campaign.sendings[0].channel.subject
      for (const contactField in contactFields) {
        message = message.replace('{' + contactField + '}', contactFields[contactField] + ' ')
      }

      return message
    },
    updateContactPreview () {
      this.messagePreview()
    },
  },
}
