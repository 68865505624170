export default class CampaignTemplateHistorySelected {
  steps = 0
  templateId = 0
  structure = {}
  disablePrevious = true
  disableNext = true

  reset (structure = {}) {
    this.steps = 0
    this.templateId = 0
    this.structure = structure
    this.disablePrevious = true
    this.disableNext = true
  }
}
