export class DefaultPlan {
  id = 0
  planProfile = 0
  planType = 0
  upToContacts = 0
  upToMailing = 0
  upToSendings = 0
  userPrice =
    {
      currencyIso: '',
      fee: 0,
      id: 0,
      mailingCost: 0,
      planId: 0,
      pushCost: 0,
      userId: 0,
    }


  constructor (response) {
    if (response) {
      this.planProfile = response.planProfile
      this.planType = response.planType
      this.upToContacts = response.upToContacts
      this.upToMailing = response.upToMailing
      this.upToSendings = response.upToSendings
      this.userPrice = response.userPrice
    }
  }
}
