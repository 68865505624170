import { ComponentsData } from './ComponentsData'

export class MailingComponentsData extends ComponentsData {
  availableAttachments = []
  availableForms = []
  availableUrls = []
  userTestEmailGroupId = null
  testEmailAddress = ''
  senders = []
  replies = []
  awsStatuses = []
  awsWhiteListed = null

  constructor (response) {
    super()
    if (response) {
      this.countries = response.countries
      this.countriesWithPhonePrefix = response.countriesWithPhonePrefix
      this.defaultTimezone = response.defaultTimezone
      this.groups = response.groups
      this.timezones = response.timezones
      this.availableAttachments = response.availableAttachments
      this.availableForms = response.availableForms
      this.availableUrls = response.availableUrls
      this.userTestEmailGroupId = response.userTestEmailGroupId
      this.testEmailAddress = response.testEmailAddress
      this.senders = response.senders
      this.replies = response.replies
      this.awsStatuses = response.awsStatuses
      this.awsWhiteListed = response.awsWhiteListed
    }
  }
}
