export default {
  name: 'GoogleAnalythics',
  props: ['campaign'],
  computed: {
    urlPreview: function () {
      let urlExample = 'https://www.example.com'
      if (this.campaign.sendings[0].channel.utmSource) {
        urlExample = this.addUrlParam(urlExample, 'utm_source', this.campaign.sendings[0].channel.utmSource)
      }
      if (this.campaign.sendings[0].channel.utmMedium) {
        urlExample = this.addUrlParam(urlExample, 'utm_medium', this.campaign.sendings[0].channel.utmMedium)
      }
      if (this.campaign.sendings[0].channel.utmCampaign) {
        urlExample = this.addUrlParam(urlExample, 'utm_campaign', this.campaign.sendings[0].channel.utmCampaign)
      }
      if (this.campaign.sendings[0].channel.utmTerm) {
        urlExample = this.addUrlParam(urlExample, 'utm_term', this.campaign.sendings[0].channel.utmTerm)
      }
      if (this.campaign.sendings[0].channel.utmContent) {
        urlExample = this.addUrlParam(urlExample, 'utm_content', this.campaign.sendings[0].channel.utmContent)
      }
      return urlExample
    },
  },
  methods: {
    addUrlParam (url, param, value) {
      if (url.includes('?')) {
        return url + '&' + param + '=' + value
      } else {
        return url + '?' + param + '=' + value
      }
    },
  },
}
