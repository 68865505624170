export default {
  name: 'ModalValidateInfo',
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {}
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
  },
}
