import { DefaultPlan } from '../DefaultPlan'
import { Preview } from './Preview'
export class MailingPreview extends Preview {
  defaultPlan = new DefaultPlan()
  price = 0

  constructor (response) {
    super()
    if (response) {
      this.totalRecipients = response.totalRecipients
      this.sendingCost = response.sendingCost
      this.contactsPreview = response.contactsPreview
      this.contactsFieldsValue = response.contactsFieldsValue
      this.errors = response.errors
      this.errorsTxt = response.errorsTxt
      this.defaultPlan = new DefaultPlan(response.defaultPlan)
      this.price = response.price
      this.contactsEmail = response.contactsEmail
    }
  }
}
