export default class CampaignTemplateHistory {
  templateDefault = 0
  initSteps = 0
  limitSteps = 50
  steps = [
    {
      templateId: 0,
      structure: {},
    },
  ]

  reset () {
    this.templateDefault = 0
    this.initSteps = 0
    this.limitSteps = 50
    this.steps = [
      {
        templateId: 0,
        structure: {},
      },
    ]
  }

  addTemplate (defaultTemplate) {
    this.steps = [defaultTemplate]
  }
}
