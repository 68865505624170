import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import CertifiedEmailModal from '../CertifiedEmailModal/CertifiedEmailModal.vue'
import VueTagsInput from '@johmun/vue-tags-input'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'
import EventBus from '@/util/EventBus'

export default {
  name: 'AdvancedOptions',
  components: {
    ShippingMode,
    CertifiedEmailModal,
    VueTagsInput,
    HelpText,
  },
  props: ['campaign', 'options', 'preview'],
  data: function () {
    return {
      tag: '',
    }
  },
  methods: {
    setCertifiedMailing () {
      this.$emit('changeCertifiedMailing')
      if (this.campaign.sendings[0].channel.certified === true) {
        EventBus.$emit('showModalCertifiedEmail')
      } else {
        this.campaign.sendings[0].channel.certified = 0
      }
    },


    confirmCertifiedEmail () {
      this.campaign.sendings[0].channel.certified = 1
      EventBus.$emit('hideModalCertifiedEmail')
      this.$emit('changeCertifiedEmail')
    },

    cancelCertifiedEmail () {
      this.campaign.sendings[0].channel.certified = false
      this.$emit('changeCertifiedEmail')
    },

    updateTags (newTags) {
      this.campaign.sendings[0].setUiTags(newTags)
    },

  },
}
