import EventBus from '@/util/EventBus.js'
export default {
  name: 'CertifiedEmailModal',
  data: function () {
    return {
      show: false,
    }
  },
  methods: {
    confirmCertifiedEmail () {
      this.$emit('confirmCertifiedEmail')
    },

    cancelCertifiedEmail () {
      this.$emit('cancelCertifiedEmail')
      this.show = false
    },
  },
  mounted () {
    const that = this
    EventBus.$on('showModalCertifiedEmail', function () {
      that.show = true
    })
    EventBus.$on('hideModalCertifiedEmail', function () {
      that.show = false
    })
  },
}
